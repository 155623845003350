import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { page, pageHeader, pageBody } from "./404.module.css";

const NotFoundPage = ({ location }) => {
  return (
    <Layout>
      <Seo
        pageTitle="ページが見つかりませんでした"
        pagePath={location.pathname}
      />
      <main className={page}>
        <h1 className={pageHeader}>Page not found</h1>
        <div className={pageBody}>
          <p>
            お探しのページはURLが正しく入力されていないか、削除された可能性があります。
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
